/* Variables */
:root {
  --primary-color: #3498db;
  --secondary-color: #cedad9;
  --background-color: #f4f4f4;
  --text-color: #333;
  --font-family: Arial, sans-serif;
  --font-size-base: 16px;
  --font-size-small: 14px;
  --font-size-large: 18px;
  --spacing-base: 8px;
  --spacing-small: 4px;
  --spacing-large: 16px;
}

/* Global Styles */
body {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  background-color: var(--background-color);
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Utility Classes */
.text-center {
  text-align: center;
}

.mt-1 {
  margin-top: var(--spacing-base);
}

.mt-2 {
  margin-top: calc(var(--spacing-base) * 2);
}

.p-1 {
  padding: var(--spacing-base);
}

.p-2 {
  padding: calc(var(--spacing-base) * 2);
}

/* Responsive Design */
@media (max-width: 768px) {
  body {
    font-size: var(--font-size-small);
  }
}

@media (min-width: 1200px) {
  body {
    font-size: var(--font-size-large);
  }
}
