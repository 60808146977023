/* Transparent Order Page Styles */
.order-page {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.order-page h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

.order-page .link-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.order-page .link-button:hover {
  background-color: #0056b3;
}

.order-page .cart-logo {
  display: block;
  width: 50px;
  margin: 20px 0;
}

.order-page .cart-logo img {
  width: 100%;
  height: auto;
}

.order-page h2 {
  font-size: 22px;
  margin-bottom: 15px;
  color: #333;
}

.order-page .product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.order-page .product-list .product-item {
  flex: 1 1 calc(33.333% - 30px); /* 3 items per row with spacing */
  box-sizing: border-box;
}

/* Transparent Product Card Styles */
.cartitem {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.6); /* Semi-transparent background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.cartitem:hover {
  background-color: rgba(255, 255, 255, 0.8); /* Less transparent on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.cartitem .discription {
  margin-bottom: 10px;
}

.cartitem .discription p {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.cartitem p {
  margin: 10px 0;
  font-size: 16px;
  color: #555;
}

.cartitem .countHandler {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cartitem .countHandler button {
  padding: 5px 10px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cartitem .countHandler button:hover {
  background-color: #0056b3;
}

.cartitem .countHandler input {
  width: 50px;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
}
/* No Products Message Styles */
.order-page .no-products {
  text-align: center;
  padding: 20px;
  background-color: rgba(
    255,
    235,
    235,
    0.9
  ); /* Light red with slight transparency */
  border: 1px solid #ffdddd;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.order-page .no-products p {
  font-size: 18px;
  color: #b22222; /* Dark red color */
  margin-bottom: 15px;
}

/* Button Style for No Products Section */
.order-page .no-products .link-button button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.order-page .no-products .link-button button:hover {
  background-color: #0056b3;
}
