/* SellerOrders.css */

/* Container for the seller orders */
.sellerOrders {
  padding: 20px;
  background: rgba(255, 255, 255, 0.9); /* Slightly transparent white */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ddd; /* Border for distinction */
  width: 90%; /* Adjust width as needed */
  max-width: 1200px; /* Max width for larger screens */
  margin: 20px auto; /* Center align and margin */
}

/* Header styles */
.sellerOrders h1 {
  color: #333;
  font-size: 2em;
  border-bottom: 2px solid #626c6d;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

/* Styles for loading and error messages */
.sellerOrders .loading, 
.sellerOrders .error {
  font-size: 1.2em;
  color: #ff0000; /* Error color */
  margin-bottom: 20px;
}

/* List styles */
.orderList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.orderItem {
  margin-bottom: 20px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.8); /* Slightly less transparent */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ddd; /* Border for distinction */
}

/* Order date */
.orderDate {
  color: #596466;
  font-size: 1.5em;
  margin-bottom: 10px;
}

/* User details */
.userList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.userItem {
  margin-bottom: 20px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.9); /* Slightly transparent white */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ddd; /* Border for distinction */
}

/* User details styling */
.userId, 
.companyName, 
.phoneNumber {
  color: #333;
  margin-bottom: 10px;
}

/* Order details */
.orderDetails {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap; /* Allow wrapping if not enough space */
  gap: 15px; /* Space between items */
}

/* Style each order detail item */
.orderDetail {
  flex: 1 1 calc(50% - 15px); /* Adjust item width and allow wrapping */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.95); /* Slightly transparent white */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
  margin-bottom: 15px; /* Space below each item */
}

.orderDetail p {
  margin: 5px 0;
}

/* Button styles */
button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #566566; /* Green background */
  color: white;
  cursor: pointer;
  font-size: 1em;
  margin-top: 10px;
}

button:hover {
  background-color: #3d4646; /* Darker green on hover */
}

/* Overall total */
.overallTotalItem {
  margin-top: 20px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.9); /* Slightly transparent white */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ddd; /* Border for distinction */
}
