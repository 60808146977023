/* General body styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-size: cover;
}

/* Container styling with glass effect */
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent white */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8px); /* Creates the glass effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Optional: subtle border */
}

/* Header styling */
.container h1 {
  font-size: 32px;
  color: #333; /* Text color */
  margin-bottom: 20px;
  text-align: center;
}

/* Choose Site styling */
.chooseSite {
  margin-bottom: 20px;
}

.chooseSite label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #333; /* Text color */
}

.chooseSite select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.7); /* Light transparent background for select dropdowns */
}

/* Optional: Adjust select dropdown styling */
.chooseSite select:focus {
  border-color: #007bff;
  outline: none;
}
