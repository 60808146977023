.medicalSupplySection {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.medicalSupplySection h2 {
  text-align: center;
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.medicalSupplySection ul {
  list-style-type: none;
  padding: 0;
}

.medicalSupplySection li {
  background-color: #fff;
  margin: 10px 0;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.medicalSupplySection li:hover {
  transform: translateY(-5px);
}

.medicalSupplySection li div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.medicalSupplySection li div span {
  color: #555;
  font-size: 16px;
}

.medicalSupplySection li div p {
  color: #777;
  font-size: 14px;
}

.deleteButton {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.deleteButton:hover {
  background-color: #e60000;
}

.medicalSupplySection li div input[type="number"] {
  width: 50px;
  padding: 5px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.medicalSupplySection li div button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
}

.medicalSupplySection li div button:hover {
  background-color: #22a7ff;
}

.medicalSupplySection li div p {
  margin: 10px 0;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.popupContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContainer .popupContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popupContent select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.popupContent button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popupContent button:hover {
  background-color: #0056b3;
}
