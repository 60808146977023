/* ListFavorites.css */

/* General styles for the container */
.container {
  padding: 20px;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent white */
  backdrop-filter: blur(10px); /* Glass effect */
  font-family: Arial, sans-serif;
  border-radius: 8px; /* Optional: rounded corners */
  width: 90%; /* Adjust width as needed */
  max-width: 1200px; /* Optional: max width for larger screens */
  margin: 0 auto; /* Center align */
  display: flex; /* Flexbox to align sections */
  flex-direction: column; /* Stack sections vertically */
  align-items: center; /* Center sections horizontally */
}

/* Style for each section */
.section {
  width: 100%; /* Full width of the container */
  max-width: 1200px; /* Optional: max width to control size */
  margin-bottom: 30px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8); /* Slightly less transparent */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ddd; /* Optional: border for distinction */
}

/* Header styles */
.section h1 {
  color: #333;
  font-size: 1.5em;
  border-bottom: 2px solid #a2b4b0;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

/* Ensure ResultsComponent has spacing */
.results-component {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white */
}

/* Add styling for the separator | */
.separator {
  display: inline-block;
  width: 2px;
  height: 1em;
  background-color: #6e7677; /* Color for the separator */
  margin: 0 10px; /* Space around the separator */
}

/* Style for empty states (optional) */
.section:empty::before {
  content: "No details available";
  color: #888;
  font-style: italic;
}

.message {
  margin: 20px 0;
  padding: 10px;
  background-color: #f0f8ff;
  border: 1px solid #87ceeb;
  border-radius: 4px;
  color: #000;
}
