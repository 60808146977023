/* Transparent Product Card Styles */
.cartitem {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 33%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.cartitem:hover {
  background-color: rgba(255, 255, 255, 1); /* Fully opaque on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.cartitem .discription {
  margin-bottom: 10px;
}

.cartitem .discription p {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.cartitem p {
  margin: 10px 0;
  font-size: 16px;
  color: #555;
}

.cartitem .countHandler {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cartitem .countHandler button {
  padding: 5px 10px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cartitem .countHandler button:hover {
  background-color: #0056b3;
}

.cartitem .countHandler input {
  width: 50px;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
}
