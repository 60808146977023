/* Search Page Styles */
.search-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.search-container input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.search-container button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-container button:hover {
  background-color: #0056b3;
}

.search-container p {
  color: #ff0000;
}

.search-results {
  max-height: 150px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.search-results ul {
  margin: 0;
  padding: 10px;
  list-style: none;
}

.search-results li {
  margin-bottom: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-results li:hover {
  background-color: #f0f0f0;
}

.wholesales-section {
  margin-top: 20px;
}

.wholesales-section h2 {
  font-size: 20px;
  color: #333;
}

.wholesales-section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.wholesales-section div {
  margin-bottom: 20px;
}

.wholesales-section div:last-of-type {
  margin-bottom: 0;
}
