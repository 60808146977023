/* Page Styles */
.page-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.page-container input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
}

.page-container .results-container {
  margin-top: 10px;
}


.page-container .error {
  color: #ff0000;
  font-size: 16px;
  margin-top: 10px;
}


