/* App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  background-color: whitesmoke; /* Sunset orange color */


}
