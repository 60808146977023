/* Product Card Styles */
.product {
  max-width: 300px; /* Adjust based on your layout needs */
  margin: 15px auto;
  padding: 15px;
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* Semi-transparent white background */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;
}

.product.expanded {
  max-height: 600px; /* Adjust based on content size */
  overflow: auto;
}

.product .discription {
  margin-bottom: 20px;
}

.product .discription p {
  margin: 5px 0;
}

.product .discription p:first-of-type {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.product .discription p:last-of-type {
  font-size: 16px;
  color: #555;
}

.product .addtocartbtn {
  margin-top: auto; /* Pushes button to the bottom of the card */
}

.product .addtocartbtn button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product .addtocartbtn button:hover {
  background-color: #0056b3;
}

.product .addtocartbtn button b {
  margin-left: 5px;
  font-weight: bold;
}

.product .details {
  margin-top: 15px;
  font-size: 14px;
  color: #666;
  text-align: left;
}

.product .details p {
  margin: 5px 0;
}
