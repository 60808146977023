/* General Styling */
.shop-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}

.shop-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
}

/* Button Container */
.shop-buttons {
  display: flex;
  gap: 20px;
}

/* Button Styling */
.shop-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.shop-button .icon {
  margin-right: 10px;
  font-size: 1.5rem;
}

/* Specific Button Colors */
.medicine {
  background-color: #3b82f6;
}

.supplies {
  background-color: #41f1f7;
}

/* Hover Effects */
.shop-button:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}
