/* Glass-like background styling */
.container {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1); /* White background with transparency */
  backdrop-filter: blur(10px); /* Blur effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light border for definition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Optional: Full page background styling */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;

  background-size: cover;
}

div {
  font-family: Arial, sans-serif;
  color: #333;
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #007BFF;
}

p {
  font-size: 16px;
  margin: 8px 0;
  line-height: 1.5;
}

h2 {
  font-size: 20px;
  margin-top: 20px;
  color: #007BFF;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

button {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.icon {
  margin-right: 8px;
}
