/* Styles for the Add Medicine for Sale section */
.buttons {
  display: flex;
}
.addMedicineSection {
  padding: 20px;
  background: rgba(240, 240, 240, 0.95); /* Slightly transparent light grey */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ddd; /* Light border */
  max-width: 800px; /* Max width for larger screens */
  margin: 20px auto; /* Center align and margin */
}

.addLink {
  position: relative;
  margin-left: 30px;
}

.addMedicineSection h1 {
  color: #333;
  font-size: 2em;
  margin-bottom: 20px;
}

/* Styles for the link */
.addMedicineSection .addLink {
  display: inline-block;
  padding: 10px 15px;
  background-color: #4caf50; /* Green background */
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.addMedicineSection .addLink:hover {
  background-color: #45a049; /* Darker green on hover */
}

/* Styles for the MedicineStockDisplay component */
.medicineStockDisplay {
  margin-top: 20px;
}
