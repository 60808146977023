/* Import global styles with variables */
@import url("../global.css"); /* Adjust the path to where your global styles are saved */

/* Navbar Styles */
.Navbar {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--primary-color);
}

.Navbar > .nav-logo {
  font-weight: 700;
  font-size: var(--font-size-large);
  margin: var(--spacing-base);
  color: #ffffff;
}

.Navbar > .nav-items {
  display: flex; /* Ensure nav-items are displayed in a row */
  align-items: center; /* Align items vertically center */
}

.Navbar > .nav-items > .nav-link {
  color: #ffffff;
  font-size: var(--font-size-base);
  text-decoration: none;
  margin: var(--spacing-base);
  position: relative;
  opacity: 0.9;
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
}

.Navbar > .nav-items > .nav-link:hover {
  opacity: 1;
}

.Navbar > .nav-items > .nav-link::before {
  content: "";
  position: relative;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #ffffff;
  transition: all 0.45s;
}

.Navbar > .nav-items > .nav-link:hover::before {
  width: 100%;
}

.Navbar > .nav-toggle {
  display: none;
}

.Navbar > .nav-items .nav-content {
  display: flex;
  align-items: center;
}

.Navbar > .nav-items .nav-content .text {
  margin-right: 8px; /* Space between icon and text */
  font-size: var(--font-size-large);
}

.Navbar > .nav-items .nav-content .icon {
  font-size: var(--font-size-large); /* Adjust icon size if needed */
}

@media (max-width: 700px) {
  .Navbar > .nav-items {
    position: absolute;
    top: 100px;
    display: flex;
    flex-direction: column;
    background: var(--primary-color);
    left: 0;
    width: 35%;
    height: 100%;
    transform: translateX(-100%);
    transition: all 0.45s;
    z-index: 10; /* Ensure it overlays other content */
  }

  .Navbar > .nav-items > .nav-link::before {
    background: transparent;
  }

  /* .Navbar > .nav-items > .nav-link {
    font-size: var(--font-size-small); /* Smaller font size when open */
  /* } */
  .Navbar > .nav-items .nav-content .text {
    display: none; /* Hide text when navbar is open */
  }

  .Navbar > .nav-items .nav-content .icon {
    margin-right: 0; /* Remove margin if not needed */
  }

  .Navbar > .nav-items > .nav-link {
    font-size: var(--font-size-base); /* Keep font-size for icons */
  }

  .Navbar > .nav-items.open {
    transform: translateX(0);
  }

  .Navbar > .nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .nav-toggle > .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #ffffff;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }

  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open > .bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open > .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
}
