@import url("../../../global.css"); /* Adjust the path to where your global styles are saved */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.text {
  color: var(--secondary-color);
  font-family: sans-serif;
}

.ring {
  position: absolute;
  width: 90px;
  height: 90px;
  border: 1px solid transparent;
  border-radius: 50%;
  border-bottom: 5px solid var(--color, var(--secondary-color));
  animation: rotate1 1s linear infinite;
}

@keyframes rotate1 {
  from {
    transform: rotateX(50deg) rotateZ(110deg);
  }
  to {
    transform: rotateX(50deg) rotateZ(470deg);
  }
}

.ring:nth-child(2) {
  --color: var(--secondary-color);
  animation-name: rotate2;
}

@keyframes rotate2 {
  from {
    transform: rotateX(20deg) rotateY(50deg) rotateZ(20deg);
  }
  to {
    transform: rotateX(20deg) rotateY(50deg) rotateZ(380deg);
  }
}

.ring:nth-child(3) {
  --color: var(--secondary-color);
  animation-name: rotate3;
}

@keyframes rotate3 {
  from {
    transform: rotateX(40deg) rotateY(130deg) rotateZ(450deg);
  }
  to {
    transform: rotateX(40deg) rotateY(130deg) rotateZ(90deg);
  }
}

.ring:nth-child(4) {
  --color: var(--secondary-color);
  animation-name: rotate4;
}

@keyframes rotate4 {
  from {
    transform: rotateX(70deg) rotateZ(270deg);
  }
  to {
    transform: rotateX(70deg) rotateZ(630deg);
  }
}
