.order-detail-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
}

.additional-data-card {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* Semi-transparent white background */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.additional-data-card h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.order-item {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Slightly transparent background for individual orders */
}

.order-item h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #555;
}

.order-item p {
  margin: 5px 0;
  font-size: 16px;
  color: #666;
}
