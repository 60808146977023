.bin-card-container {
  width: 100%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.product-description {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

.product-description p {
  margin: 5px 0;
}

.bin-card-table {
  width: 100%;
  border-collapse: collapse;
}

.bin-card-table th, .bin-card-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.bin-card-table th {
  background-color: #f4f4f4;
}

.bin-card-table tr:nth-child(even) {
  background-color: #f9f9f9;
}
.bin-card-table .error-row td {
  color: red;
  font-weight: bold;
}