.not-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f4f6f8;
  color: #333;
}

.not-found-content {
  text-align: center;
  max-width: 600px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.not-found-content h1 {
  font-size: 6rem;
  margin: 0;
  color: #ff6b6b;
}

.not-found-content h2 {
  font-size: 2rem;
  margin: 20px 0;
}

.not-found-content p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 30px;
}

.back-home- {
  display: inline-block;
  padding: 12px 24px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.back-home-button:hover {
  background-color: #0056b3;
}
