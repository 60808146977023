/* Styles for the medicines display section */
.medicinesSection {
  padding: 20px;
  background: rgba(255, 255, 255, 0.95); /* Slightly transparent white */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ddd; /* Border for distinction */
  max-width: 800px; /* Max width for larger screens */
  margin: 20px auto; /* Center align and margin */
}

.medicinesSection h2 {
  color: #333;
  font-size: 1.8em;
  margin-bottom: 20px;
}

/* List styles */
.medicinesSection ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.medicinesSection li {
  margin-bottom: 15px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.9); /* Slightly less transparent white */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ddd; /* Border for distinction */
  display: flex;
  flex-direction: column;
}

.medicinesSection li > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.medicinesSection li > div span {
  margin-right: 10px;
}

.medicinesSection li > div:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
}

/* Expanded details */
.medicinesSection li > div + div {
  padding: 10px;
  background: rgba(245, 245, 245, 0.8); /* Slightly transparent grey */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ddd; /* Border for distinction */
  margin-top: 10px;
}

/* Button styles */
.medicinesSection button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50; /* Green background */
  color: white;
  cursor: pointer;
  font-size: 1em;
  margin: 5px;
}

.medicinesSection button:hover {
  background-color: #45a049; /* Darker green on hover */
}

/* Delete button styling */
.medicinesSection .deleteButton {
  background-color: #f44336; /* Red background for delete */
}

.medicinesSection .deleteButton:hover {
  background-color: #e53935; /* Darker red on hover */
}
