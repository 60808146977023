/* Results Component Styles */
.results-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.results-component:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.results-component a {
  font-size: 18px;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.results-component a:hover {
  color: #0056b3;
}

.results-component div {
  display: flex;
  align-items: center;
}

.favorite-button {
  margin-left: 15px;
}
