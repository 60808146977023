/* General body styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-size: cover;
}

/* Invoice container styling */
.invoice {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background: rgba(
    255,
    255,
    255,
    0.9
  ); /* Slightly transparent white background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Paragraph styling within the invoice */
.invoice p {
  margin: 10px 0;
  font-size: 16px;
  color: #333; /* Dark text color for readability */
  align-items: center;
  justify-content: center;
}

/* Button styling */
.invoice button {
  display: block;
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.invoice button:hover {
  background-color: #0056b3;
}
