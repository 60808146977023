/* General container styling with blue glass effect */
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(0, 0, 255, 0.2); /* Blue tint with transparency */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px); /* Glass effect */
  border: 1px solid rgba(0, 0, 255, 0.3); /* Blue border to enhance the glass effect */
}

/* Header styling */
.container h2 {
  font-size: 28px;
  color: #fff; /* White text for contrast */
  margin-bottom: 20px;
  text-align: center;
}

.checkAdress {
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  margin-bottom: 20px;
   margin-left: 50px;
}

.checkAdress:hover {
  background-color: #0056b3;
}

/* Form styling */
form {
  display: flex;
  flex-direction: column;
}

/* Form group styling */
form div {
  margin-bottom: 15px;
}

form label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #fff; /* White text for contrast */
}

form input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

form input[type="text"]:focus {
  border-color: #007bff;
  outline: none;
}

/* Error message styling */
form p {
  color: #ff0000; /* Red color for error messages */
  font-size: 14px;
  margin: 5px 0 0;
}

/* Button styling */
button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Additional component styling */
.UserLocationSelector,
.CoordinateInput,
.YourFormComponentForlatitudeAndComponent {
  margin-bottom: 20px;
}
