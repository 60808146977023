/* PopupResonForDiduction.css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 300px; /* Adjust width as needed */
  max-height: 80vh; /* Adjust height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.options-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.option-item {
  padding: 10px;
  cursor: pointer;
}

.option-item.selected {
  background-color: #ddd;
}

button {
  margin-top: 10px;
  margin-left: 10px;
  padding: 10px;
}
