.profile-page {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px; /* Adjust the max width as needed */
  margin: 20px auto; /* Center the page horizontally */
}

h1 {
  text-align: center;
  color: #333;
}

.go-back-btn {
  align-self: flex-start;
  margin-bottom: 20px;
}

.profile-info {
  margin-bottom: 20px;
}

.info-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info-row label {
  font-weight: bold;
  margin-right: 10px;
  width: 150px;
}

.profile-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.info-row input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.info-row span {
  font-size: 16px;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-btn,
.apply-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.edit-btn:hover,
.apply-btn:hover {
  background-color: #0056b3;
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile-action-btn {
  background-color: #e0e0e0;
  color: #333;
  border: none;
  border-radius: 5px;
  padding: 15px 20px;
  margin: 5px 0;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.profile-action-btn:hover {
  background-color: #ccc;
}

.logout-btn {
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 15px 20px;
  margin: 5px 0;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  justify-content: flex-end;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.logout-btn:hover {
  background-color: #c82333;
}

@media (max-width: 768px) {
  .button-group {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .profile-action-btn,
  .logout-btn {
    width: auto;
    margin: 5px;
  }
}

.error {
  text-align: center;
  padding: 20px;
}
