.invoices-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.invoices-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.invoices-table th,
.invoices-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.invoices-table th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 1;
}

.invoices-table tr:hover {
  background-color: #f1f1f1;
}


.error-message {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  color: #007bff;
}

.error-message {
  color: #ff4d4d;
}
