.orders-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.page-title {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.orders-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.order-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.order-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.order-link {
  text-decoration: none;
  color: #333;
}

.shop-name {
  font-size: 1.5em;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.phone-number,
.total-amount,
.order-date {
  font-size: 1em;
  color: #666;
  margin-bottom: 8px;
}

.delivery-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #007bff;
  margin-top: 10px;
}

.delivery-link:hover {
  text-decoration: underline;
}

.view-products-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.2s;
}

.view-products-button:hover {
  background-color: #0056b3;
}

.icon {
  margin-left: 5px;
}
