.invoice-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  height: 80px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start; /* Use flex-start instead of start */
  align-items: center;
  background: var(--primary-color);
}

.print-button {
  margin-top: 5px;
  margin-right: 5px;
  height: 80px;
  width: 80px;
  font-size: 40px;
  padding: 10px 10px;
  background-color: #00f7ff; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.print-button:hover {
  background-color: #0056b3; /* Darker shade of primary color */
}

.print-button svg {
  width: 100%; /* Adjust width relative to button size */
  height: 100%; /* Adjust height relative to button size */
}

.invoice-container {
  background-color: white; /* Ensure background is white */
  padding: 20px;
  border-radius: 8px;
}

.header-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust space between sections as needed */
}

.shop-info {
  flex: 1;
  min-width: 300px; /* Adjust as needed */
  margin-left: 100px;
}

.info-sections {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjust space between sections as needed */
}

.sales-info,
.customer-info {
  flex: 1;
  min-width: 300px; /* Adjust as needed */
}

.products-table {
  margin-top: 20px;
}

.products-table table {
  width: 100%;
  border-collapse: collapse;
}

.products-table th,
.products-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.products-table th {
  background-color: #f2f2f2;
}

.products-table td {
  word-wrap: break-word; /* Break long text */
}

.total-in-words {
  display: flex; /* Use flexbox to align items horizontally */
  align-items: center; /* Center items vertically */
  gap: 10px; /* Space between the header and the paragraph */
  flex-wrap: wrap; /* Allow wrapping if content is too long */
  margin-top: 20px; /* Space above the section */
}

.total-in-words h2 {
  margin: 0; /* Remove default margin */
}

.total-in-words p {
  margin: 0; /* Remove default margin */
  max-width: 100%; /* Ensure the paragraph takes up the available space */
  word-wrap: break-word; /* Allow text to break and wrap onto the next line */
  overflow-wrap: break-word; /* Break long words to avoid overflow */
}

.footerBody {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust gap as needed */
}

.reciverInput {
  margin: 10px 0; /* Adjust margin as needed */
}

.breakLine {
  border-top: 1px solid #000; /* Adjust color as needed */
  margin: 10px 0; /* Adjust spacing as needed */
}

.detailHead,
.detail {
  flex: 1 1 45%; /* Adjust width to fit in two lines */
  margin: 5px 0; /* Adjust margin as needed */
  white-space: pre-line; /* Allows wrapping of text within the element */
}

/* Print specific styles */
@media print {
  .invoice-page {
    width: 100%;
    height: auto;
  }

  .print-button {
    display: none; /* Hide print button during printing */
  }

  .invoice-container {
    background-color: white; /* Ensure background is white */
    width: 100%;
    padding: 0;
    margin: 0;
    page-break-inside: avoid; /* Avoid page break inside container */
  }

  .header,
  .invoice-footer {
    page-break-after: avoid; /* Prevent page breaks after header and footer */
  }

  .products-table {
    page-break-inside: avoid; /* Avoid page break inside table */
  }

  /* Ensure content fits on one page or starts on a new page if necessary */
  @page {
    margin: 20mm; /* Adjust margins as needed */
   } 

  /* Make sure the content fills the page correctly */
  .invoice-container::after {
    content: "";
    display: block;
    height: 100vh; /* Ensure the container is always as tall as the viewport */
    background-color: white; /* Set background to white */
  }
}
