/* Importing global styles with variables */
@import url("../../global.css"); /* Adjust the path to where your global styles are saved */

/* CSS file */
html {
  scroll-behavior: smooth;
}

/* Navbar Styles */
.Navbar {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--primary-color);
}

.Navbar > .nav-logo {
  font-weight: 700;
  font-size: var(--font-size-large);
  margin: var(--spacing-base);
  color: #ffffff;
}

.nav-logo {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.Navbar > .nav-items > a,
.Navbar > .nav-items > .nav-link,
.Navbar > .nav-items > select,
.Navbar > .nav-items > button {
  font-size: var(--font-size-base);
  margin: var(--spacing-base);
  width: max-content;

  position: relative;
  opacity: 0.9;
  background: transparent;
  border: none;
  cursor: pointer;
}

.Navbar > .nav-items > a,
.Navbar > .nav-items > .nav-link {
  color: var(--text-color); /* Black color for links */
  padding-top: 0.1cap;
  text-decoration: none;
}

.Navbar > .nav-items > select {
  color: var(--text-color); /* Black color for dropdown text */
  background: #ffffff; /* Set background color for better visibility */
  border: 2px solid var(--text-color); /* Optional: border to match text color */
  padding: var(--spacing-small);
  border-radius: var(--spacing-small);
}

.Navbar > .nav-items > select option {
  background: #ffffff;
  color: var(--text-color); /* Black color for option text */
}

.Navbar > .nav-items > button {
  background: #ffffff;
  color: var(--primary-color);
  padding-bottom: 10cap;
  padding: var(--spacing-base) var(--spacing-large);
  border-radius: var(--spacing-small);
  border: 2px solid var(--primary-color);
  cursor: pointer;
}

.Navbar > .nav-items > button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.Navbar > .nav-items > button:hover {
  background: #eeeeee;
}

.Navbar > .nav-items > a::before,
.Navbar > .nav-items > .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #ffffff;
  transition: all 0.45s;
}

.Navbar > .nav-items > a:hover::before,
.Navbar > .nav-items > .nav-link:hover::before {
  width: 100%;
}

.Navbar > .nav-toggle {
  display: none;
}

@media (max-width: 700px) {
  .Navbar > .nav-items {
    position: absolute;
    top: 60px;
    display: flex;
    flex-direction: column;
    background: var(--secondary-color);
    left: 0;
    width: 50%;
    height: 100%;
    transform: translateX(-100%);
    transition: all 0.45s;
  }

  .Navbar > .nav-items > a::before,
  .Navbar > .nav-items > .nav-link::before {
    background: transparent;
  }

  .Navbar > .nav-items.open {
    transform: translateX(0);
  }

  .Navbar > .nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .nav-toggle > .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #ffffff;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }

  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open > .bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open > .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
}

.hero-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background: url("../../pics/loginPagepic.jpg") no-repeat center center;
  background-attachment: fixed; /* Optional: keeps the image static while scrolling */
  background-size: 100% auto; /* Stretch to fit the x-axis, height adjusts automatically */
  background-position: center center;
  color: #fff;
  text-align: center;
}

.hero-img {
  width: 50%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
  margin-right: 20px;
}

.hero-text {
  font-family: Arial, sans-serif;
  max-width: 600px;
}

.hero-text h1 {
  font-size: 2rem; /* Reduce font size for better fit on smaller screens */
  margin-bottom: 10px; /* Add space below the h1 */
  color: black; /* Set font color to black */
}

.hero-text h2 {
  font-size: 1.5rem; /* Adjust size of h2 for consistency */
  margin-bottom: 8px; /* Add space below the h2 */
  color: black; /* Set font color to black */
}

@media (max-width: 768px) {
  .hero-content {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    justify-content: center;
    padding: 20px 10px; /* Adjust padding for smaller screens */
    background-color: #007bff;
    color: #fff;
    text-align: center;
  }

  .hero-img {
    width: 80%; /* Increase image width for better visibility */
    max-width: 400px; /* Limit the maximum size */
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px; /* Add spacing below the image */
    margin-right: 0; /* Remove right margin to center the image */
  }

  .hero-text {
    max-width: 90%; /* Adjust text width to fit smaller screens */
    margin: 0 auto; /* Center the text block */
  }
  .hero-text h1 {
    font-size: 1.8rem; /* Reduce font size for better fit on smaller screens */
    margin-bottom: 10px; /* Add space below the h1 */
    color: black; /* Set font color to black */
  }

  .hero-text h2 {
    font-size: 1.5rem; /* Adjust size of h2 for consistency */
    margin-bottom: 8px; /* Add space below the h2 */
  }

  .hero-text p {
    font-size: 1rem; /* Set a comfortable font size for the paragraph */
    line-height: 1.5; /* Adjust line height for readability */
    margin-bottom: 15px; /* Add space below the paragraph */
  }
}

section {
  padding: 40px 20px;
  text-align: center;
}

.section-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.section-img {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.section-text {
  max-width: 600px;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 10px 0;
}

footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px;
}

footer a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

footer a:hover {
  text-decoration: underline;
}
