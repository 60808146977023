/* General Styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
    margin: 0;
    padding: 10;
    line-height: 1.6;
    color: #333;
}

.containered {
  max-width: 800px; /* Limits the width for better readability */
  margin: 0 auto; /* Centers the container on the page */
  padding: 20px; /* Adds some padding around the content */
  background-color: #ffffff; /* White background for a clean look */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-family: Arial, sans-serif; /* Clean and readable font */
  color: #333; /* Dark text for good contrast */
  line-height: 1.6; /* Improves readability */
}

h1, h2, h3, h4 {
    color: #1a73e8;
}

h1 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    text-align: center;
}

h2 {
    font-size: 2em;
    margin-top: 2em;
    border-bottom: 2px solid #ddd;
    padding-bottom: 0.5em;
}

h3 {
    font-size: 1.6em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
}

h4 {
    font-size: 1.4em;
    margin-top: 1.2em;
}

p {
    font-size: 1.1em;
    margin-bottom: 1em;
    text-align: justify;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

/* Links */
a {
    color: #1a73e8;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Lists */
ul {
    list-style-type: disc;
    padding-left: 40px;
}

ul li {
    margin-bottom: 0.5em;
}

/* Section Styling */
/* section {
    max-width: 1000px;
    margin: 2em auto;
    padding: 2em;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
} */

/* Footer */
footer {
    text-align: center;
    font-size: 1em;
    padding: 1em;
    background-color: #333;
    color: #fff;
}

/* Form Fields (if any) */
input, textarea {
    width: 100%;
    padding: 0.8em;
    margin-bottom: 1em;
    border-radius: 5px;
    border: 1px solid #ddd;
}

/* Responsive Design */
@media (max-width: 768px) {
    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.8em;
    }

    p {
        font-size: 1em;
    }

    ul {
        padding-left: 20px;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 1.6em;
    }

    h2 {
        font-size: 1.4em;
    }

    p {
        font-size: 0.9em;
    }

    ul {
        padding-left: 15px;
    }
}
