/* General body styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.container {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent white */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8px); /* Adjust blur for glass effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Optional: subtle border */
}

/* Header styling */
.container h2 {
  font-size: 28px;
  color: #333; /* Keep text color unchanged */
  margin-bottom: 20px;
  text-align: center;
}

/* Select input styling */
.container select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 16px;
}

/* Form input styling */
.formInput,
.form-group {
  margin-bottom: 15px;
}

.formInput label,
.form-group label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #333; /* Keep text color unchanged */
}

.formInput input,
.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.formInput p,
.form-group p {
  color: #ff0000; /* Red color for error messages */
  font-size: 14px;
  margin: 5px 0 0;
}

/* Button styling */
button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Additional component styling */
.UserLocationSelector, .CoordinateInput, .YourFormComponentForlatitudeAndComponent {
  margin-bottom: 20px;
}
