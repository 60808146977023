/* Overall form styling */
.bank-form {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

/* Form header */
.bank-form h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
  font-size: 24px;
}

/* Container for each bank entry */
.bank-entry {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

/* Bank logo styling */
.bank-logo img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 4px;
}

/* Label styling */
.bank-entry label {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: #000000;
}

/* Input field styling */
.bank-entry input[type="text"] {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 16px;
}

/* Submit button styling */
.submit-button {
  display: block;
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Error message styling */
.error-message {
  color: #ff0000;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

/* Loading message styling */
.loading-message {
  text-align: center;
  font-size: 18px;
  color: #666666;
}
