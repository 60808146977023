/* General body styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-size: cover; /* Assuming background image is set elsewhere */
}

/* Form container styling with glass effect */
.form-inner {
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent white for glass effect */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px); /* Glass effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Optional: subtle border to enhance the glass effect */
  text-align: center;
}

/* Header styling */
.form-inner h2 {
  font-size: 28px;
  color: #333; /* Keep text color unchanged */
  margin-bottom: 20px;
}

/* Error message styling */
.error {
  color: #ff0000; /* Red color for error messages */
  margin-bottom: 20px;
}

/* Form group styling */
.form-group {
  margin-bottom: 15px;
  text-align: left; /* Align label and input text to the left */
}

.form-group label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #333; /* Keep text color unchanged */
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

/* Button styling */
input[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

input[type="submit"]:hover {
  background-color: #0056b3;
}

/* Additional text styling */
.form-inner h1 {
  font-size: 16px;
  color: #666;
  margin-top: 20px;
}
