/* General body styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-size: cover;
  background-color: #f5f5f5; /* Light background for the page */
}

/* Main container styling */
.container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: rgba(
    255,
    255,
    255,
    0.9
  ); /* Slightly transparent white background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Heading styling */
.page-heading {
  font-size: 32px;
  color: #333; /* Dark color for better readability */
  text-align: center;
  margin-bottom: 20px;
}

/* Go Back Button styling */
.go-back-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block;
  margin: 0 auto 20px;
}

.go-back-button:hover {
  background-color: #0056b3;
}

/* File Actions Container styling */
.file-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* File Group styling */
.file-group {
  margin-bottom: 20px;
}

/* View File Button styling */
.view-file-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.view-file-button:hover {
  background-color: #0056b3;
}

/* File Input styling */
.file-input {
  display: block;
  margin: 10px 0 20px;
  padding: 20px;
  border: 1px solid gold;
}

/* Upload Button styling */
.upload-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block;
  margin: 20px auto;
}

.upload-button:hover {
  background-color: #0056b3;
}

/* Status Message styling */
.status-message {
  color: #007bff; /* Color to match the buttons */
  text-align: center;
  margin-top: 20px;
}
