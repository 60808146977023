/* Cart Page Styles */
.cart {
  max-width: 1200px; /* Adjust based on your layout needs */
  margin: 20px auto;
  padding: 20px;
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* Semi-transparent white background */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.title {
  margin-bottom: 20px;
  text-align: center;
}

.title h1 {
  font-size: 24px;
  color: #333;
}

/* Cart Items Flexbox Layout */
.cartItems {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  gap: 15px; /* Space between each item */
  padding: 15px;
  justify-content: space-between; /* Distribute space between items */
}

.cartItems > div {
  flex: 1 1 calc(25% - 15px); /* Adjust to show at least 4 items per row */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

/* Responsive Design */
@media (max-width: 1200px) {
  .cartItems > div {
    flex: 1 1 calc(33.33% - 15px); /* Show at least 3 items per row */
  }
}

@media (max-width: 768px) {
  .cartItems > div {
    flex: 1 1 calc(50% - 15px); /* Show at least 2 items per row */
  }
}

@media (max-width: 480px) {
  .cartItems > div {
    flex: 1 1 100%; /* Show 1 item per row */
  }
}

.cartItems > div {
  margin-bottom: 15px; /* Spacing between cart items */
}

.checkOut {
  margin-top: 20px;
  text-align: center;
}

.checkOut p {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.checkOut button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
}

.checkOut button:hover {
  background-color: #0056b3;
}

.checkOut button:focus {
  outline: none;
}

.checkOut h1 {
  font-size: 24px;
  color: #333;
}

.checkOut button {
  margin: 10px;
}

.checkOut button + button {
  margin-left: 10px; /* Spacing between buttons */
}
