/* Wrapper for the form */
.form-wrapper {
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  border: 1px solid silver;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

/* Form Title */
.form-wrapper h2 {
  text-align: center;
  color: black;
  font-weight: 600;
  margin-bottom: 20px;
  border-bottom: 2px solid goldenrod;
  padding-bottom: 10px;
}

/* Form Input Container */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

/* Input Labels */
.form-group label {
  font-weight: 500;
  color: black;
  margin-bottom: 5px;
}

/* Form Inputs */
.form-group input,
.form-group select,
.form-group textarea {
  padding: 10px;
  border: 1px solid silver;
  border-radius: 5px;
  background-color: #f8f8f8;
  font-size: 16px;
  color: black;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: lightblue;
  outline: none;
}

/* Submit Button */
button[type="submit"] {
  width: 100%;
  background-color: lightblue;
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button[type="submit"]:hover {
  background-color: rgb(84, 206, 255);
  box-shadow: 0 4px 12px rgba(85, 195, 238, 0.945);
}

button[type="submit"]:active {
  background-color: goldenrod;
  color: rgb(84, 221, 255);
}

/* Styling for textareas */
textarea {
  min-height: 100px;
   width: 100%;
}

/* Small golden separator lines between inputs */
.form-group:not(:last-child) {
  border-bottom: 1px solid goldenrod;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

/* Adjustments for the responsiveness */
@media (max-width: 768px) {
  .form-wrapper {
    padding: 15px;
  }

  button[type="submit"] {
    font-size: 14px;
    padding: 10px;
  }
}
