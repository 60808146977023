/* login.css or similar */
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  background-color: #ccc; /* Set background to a solid color */
}

.loginwraper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}
.checkbox {
  margin-right: 5px; /* Optional: Adjust spacing between checkbox and label */
}

label[for="terms"] {
  margin-left: 5px;
}

label {
  font-size: 0.9rem;
}

.loginwraper form {
  background: rgba(255, 255, 255, 0.8); /* Glass-like effect */
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 5, 95, 0.2);
  width: 100%;
  max-width: 500px; /* Adjust width as needed */
  text-align: center;
  position: relative;
}

.loginwraper form a:hover {
  text-decoration: underline;
}

.checkbox-container {
  display: flex;
  align-items: center; /* Align the checkbox and text vertically */
  gap: 5px; /* Space between checkbox and label */
  white-space: nowrap; /* Prevent wrapping of text to a new line */
}

.checkbox-container label {
  font-family: Arial, sans-serif; /* Clean and readable font */
  color: #333; /* Dark text for good contrast */

  margin: 0; /* Reset margin */
  white-space: nowrap; /* Prevent wrapping of text */
}

/* Styling the password input and icon */
.password-container {
  position: relative; /* To position the icon inside the input */
}
.password-container input {
  width: 100%;
  padding-right: 2rem; /* Add space for the eye icon */
}
.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999; /* Change color as needed */
}

/* Optional: Style the icon on hover */
.toggle-password:hover {
  color: #333;
}
.loginwraper h1 {
  margin-bottom: 1rem;
}

.loginwraper select,
.loginwraper input,
.loginwraper button {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #002853;
  border-radius: 5px;
}

.loginwraper button {
  text-align: center;
  background: #007bff;
  color: #007bff;
  border: none;
  cursor: pointer;
}

.loginwraper button:disabled {
  background: #ccc;
}

.loginwraper p {
  margin: 0.5rem 0;
}
.loginwraper .loginerror {
  color: red; /* Set error color to red */
}

.loginwraper a {
  color: #007bff;
  text-decoration: none;
}

.loginwraper a:hover {
  text-decoration: underline;
}
.aterm {
  font-family: Arial, sans-serif; /* Clean and readable font */
  color: #333; /* Dark text for good contrast */
}
